// ------------------------------------------------------------------------
// -- JS GLOBAL
// ------------------------------------------------------------------------

// ------------------------------------------
// -- TOGGLE MAIN NAV (MOBILE)
// ------------------------------------------

function toggleNav() {
    var btn = this
    var nav = document.getElementById(this.getAttribute('aria-controls'));
    if ( btn.getAttribute('aria-expanded') === 'true' ) {
        btn.setAttribute('aria-expanded', 'false');
        btn.classList.remove('togglenav--active');
        nav.classList.add('nav--hidden');
    } else {
        btn.setAttribute('aria-expanded', 'true');
        btn.classList.add('togglenav--active');
        nav.classList.remove('nav--hidden');
    }
}

document.getElementById('nav').classList.add('nav--hidden');
document.getElementById('js__toggleNav').addEventListener('click', toggleNav);